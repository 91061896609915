<template>
  <div class="mstaff-topbar-wrapper">
    <topbar :isActive="isMenuOpen">
      <!-- Notification dropdown -->
      <template #right-section>
        <fd-button
          v-if="$mq === 'xs'"
          class="circle"
          @click="openNotificationFullModal"
        >
          <i class="topbar-notification-icon fas fa-bell"></i>
          <span v-if="hasUnreadNotification" class="unread-badge"></span>
        </fd-button>
        <dropdown-button
          v-else
          ref="notificationDropdown"
          buttonClass="circle flat"
          :closeOnContentClick="false"
          @openDropdown="initNotifications"
        >
          <template #buttonContent>
            <i class="topbar-notification-icon fas fa-bell"></i>
            <span v-if="hasUnreadNotification" class="unread-badge"></span>
          </template>

          <div class="card">
            <notification-panel
              :isLoading="isNotificationLoading"
              :notifications="notifications"
              :redirectIdentifier="(val) => hasRedirect(val)"
              :unreadOnly.sync="isNotificationUnreadOnly"
              @item-click="onNotificationItemClick"
              @infinite-scroll="loadNextNotifications"
            ></notification-panel>
          </div>
        </dropdown-button>
      </template>
    </topbar>
    <!-- Notification modal (mobile) -->
    <modal v-model="showNotificationModal" fullscreen>
      <modal-content class="card">
        <notification-panel
          fullscreen
          :isLoading="isNotificationLoading"
          :notifications="notifications"
          :unreadOnly.sync="isNotificationUnreadOnly"
          @item-click="onNotificationItemClick"
          @infinite-scroll="loadNextNotifications"
          @close="closeNotification"
        ></notification-panel>
      </modal-content>
    </modal>
  </div>
</template>

<script>
import { notification as notificationAPI } from "@/api";
import { HANDLER } from "@/helpers/notification/handler";
export default {
  components: {
    Topbar: () =>
      import("@/components/GlobalComponents/FdLayout/TopbarComponents/Topbar"),
    DropdownButton: () =>
      import("@/components/GlobalComponents/DropdownButton"),
    NotificationPanel: () =>
      import("@/components/GlobalComponents/Notifications/NotificationPanel"),
    ModalContent: () =>
      import("@/components/GlobalComponents/ModalBox/ModalContent")
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      isNotificationLoading: false,
      showNotificationModal: false,
      isNotificationUnreadOnly: true,

      notifications: [],
      notificationPage: 1,
      notificationTotalPages: 1,

      notificationAPI
    };
  },
  computed: {
    isMenuOpen() {
      return this.$store.getters.getSidebarState;
    },
    hasUnreadNotification() {
      return this.$store.getters.getHasUnreadNotification;
    }
  },
  watch: {
    isNotificationUnreadOnly() {
      this.initNotifications();
    }
  },
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.checkUnreadNotifications();
  },
  methods: {
    async initNotifications() {
      try {
        this.isNotificationLoading = true;
        // Reset notification state
        this.notificationPage = 1;
        this.notifications = [];
        // Load notifications
        await this.loadNotifications();

        this.isNotificationLoading = false;
      } catch (error) {
        this.isNotificationLoading = false;
      }
    },
    async loadNotifications() {
      const notificationPerPage = 10;
      try {
        let data = await notificationAPI.getNotifications({
          page: this.notificationPage,
          perPage: notificationPerPage,
          seen: this.isNotificationUnreadOnly ? false : null
        });

        this.notifications = [
          ...this.notifications,
          ...this._.cloneDeep(data.data)
        ];
        this.notificationTotalPages = this._.cloneDeep(
          data.meta.pagination.total_pages
        );
      } catch (error) {
        throw error;
      }
    },
    async loadNextNotifications(infScrollState) {
      // Load more if there are more pages
      if (this.notificationPage < this.notificationTotalPages) {
        this.notificationPage++;
        await this.loadNotifications();
        infScrollState.loaded();
      } else {
        infScrollState.loaded();
        infScrollState.complete();
      }
    },
    async checkUnreadNotifications() {
      this.$store.dispatch("checkHasUnreadNotification");
    },
    closeNotification() {
      this.showNotificationModal = false;
    },
    async openNotificationFullModal() {
      this.showNotificationModal = true;
      await this.initNotifications();
    },
    async onNotificationItemClick(data) {
      // Mark as read
      if (data.seen === false) {
        this.markNotificationAsRead(data.id);
      }

      // Redirect to page
      let handler = this.getRedirectHandler(data);
      if (handler) {
        handler();
      }
    },
    hasRedirect(notif) {
      return !!this.getRedirectHandler(notif);
    },
    getRedirectHandler(notif) {
      let notifPayload = notif.data.web;
      let handler;
      if (notifPayload.label) {
        handler = HANDLER.find(
          (handler) => handler.label === notifPayload.label
        );
      }
      return handler ? () => handler.handle(notifPayload.params) : undefined;
    },
    async markNotificationAsRead(id) {
      // Calls api to update
      notificationAPI.markAsSeen(id).then(() => {
        // Checks existing list has no more unread, then calls api to confirm
        let existingHasUnread =
          this.notifications.filter((notif) => notif.seen == false).length > 0;
        if (!existingHasUnread) {
          this.checkUnreadNotifications();
        }
      });

      if (this.isNotificationUnreadOnly) {
        // Removes item from list if unread only
        this.notifications = this.notifications.filter(
          (notif) => notif.id !== id
        );
      } else {
        // Update item in list if show all
        let index = this.notifications.findIndex((notif) => notif.id === id);
        this.$set(this.notifications[index], "seen", true);
      }
    }
  }
};
</script>

<style lang="scss">
.mstaff-topbar-wrapper {
  .topbar-notification-icon {
    font-size: 18px;
  }
  .unread-badge {
    background: $color-danger;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    right: 8px;
    top: 8px;
    position: absolute;
  }
}
</style>
