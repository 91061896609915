export const LABELS = {
    // PROJECT SALES
    PROJECT_RESERVATION_DETAIL: "Project Purchase Reservation",
    PROJECT_SALES_DETAIL: "Project Purchase Detail",
    PROJECT_BOOKING_DETAIL: "Project Purchase Booking",
    PROJECT_LOAN_DETAIL: "Project Purchase Bank Loan",
    PROJECT_LOAN_LIST: "Project Purchase Bank Loan List",
    PROJECT_LAWYER_DETAIL: "Project Purchase Lawyer",
    PROJECT_REFUND_DETAIL: "Project Purchase Refund",
    PROJECT_DEFECT_DETAIL: "Project Purchase Defect",

    // PROJECTS
    PROJECT_ADDED: "PROJECT_ADDED",
    PROJECT_UPDATED: "PROJECT_UPDATED",
    PROJECT_BANKER_ALLOCATED: "PROJECT_BANKER_ALLOCATED",
    PROJECT_BANKER_REMOVE: "PROJECT_BANKER_REMOVED",
    PROJECT_LAWYER_ALLOCATED: "PROJECT_LAWYER_ALLOCATED",
    PROJECT_LAWYER_REMOVED: "PROJECT_LAWYER_REMOVED",
    PROJECT_PHASE_ADDED: "PROJECT_PHASE_ADDED",
    PROJECT_PHASE_UPDATED: "PROJECT_PHASE_UPDATED",
    PROJECT_UNIT_TYPE_ADDED: "PROJECT_UNIT_TYPE_ADDED",
    PROJECT_UNIT_TYPE_UPDATED: "PROJECT_UNIT_TYPE_UPDATED",
    PROJECT_FLOOR_PLAN_ADDED: "PROJECT_FLOOR_PLAN_ADDED",
    PROJECT_FLOOR_PLAN_UPDATED: "PROJECT_FLOOR_PLAN_UPDATED",
    PROJECT_UNIT_ADDED: "PROJECT_UNIT_ADDED",
    PROJECT_UNIT_UPDATED: "PROJECT_UNIT_UPDATED",
    PROJECT_UNIT_BANKER_ALLOCATED: "PROJECT_UNIT_BANKER_ALLOCATED",
    PROJECT_UNIT_BANKER_REMOVED: "PROJECT_UNIT_BANKER_REMOVED",
    PROJECT_UNIT_LAWYER_SPA_UPDATED: "PROJECT_UNIT_LAWYER_SPA_UPDATED",
    PROJECT_UNIT_LAWYER_LA_UPDATED: "PROJECT_UNIT_LAWYER_LA_UPDATED"
};
