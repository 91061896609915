import { LABELS } from "./labels";
import router from "@/router/index";
import { throwIfNotFound } from "./utils";

import projectHandler from "./project";

export const HANDLER = [
    {
        label: LABELS.PROJECT_RESERVATION_DETAIL,
        handle: function (params) {
            throwIfNotFound(this.label, "unitId", params);
            throwIfNotFound(this.label, "projectPurchaseId", params);

            let route = {
                name: "ManageProjectReservationDetails",
                params: {
                    unitId: params.unitId,
                    id: params.projectPurchaseId
                }
            };

            router.push(route);
        }
    },
    {
        label: LABELS.PROJECT_SALES_DETAIL,
        handle: function (params) {
            throwIfNotFound(this.label, "projectPurchaseId", params);

            let route = {
                name: "ProjectSalesDetail",
                params: {
                    id: params.projectPurchaseId
                }
            };
            router.push(route);
        }
    },
    {
        label: LABELS.PROJECT_BOOKING_DETAIL,
        handle: function (params) {
            throwIfNotFound(this.label, "projectPurchaseId", params);

            let route = {
                name: "ManageProjectBookingDetail",
                params: {
                    id: params.projectPurchaseId
                }
            };
            router.push(route);
        }
    },
    {
        label: LABELS.PROJECT_LOAN_LIST,
        handle: function () {
            let route = {
                name: "ManageProjectMortgageBankLoans"
            };
            router.push(route);
        }
    },
    {
        label: LABELS.PROJECT_LOAN_DETAIL,
        handle: function (params) {
            throwIfNotFound(this.label, "bankLoanId", params);

            let route = {
                name: "ManageProjectMortgageBankLoansDetail",
                params: {
                    id: params.bankLoanId
                }
            };
            router.push(route);
        }
    },
    {
        label: LABELS.PROJECT_LAWYER_DETAIL,
        handle: function (params) {
            throwIfNotFound(this.label, "projectPurchaseId", params);

            let route = {
                name: "ManageProjectLegalProcessDetail",
                params: {
                    id: params.projectPurchaseId
                }
            };
            router.push(route);
        }
    },
    {
        label: LABELS.PROJECT_REFUND_DETAIL,
        handle: function (params) {
            throwIfNotFound(this.label, "projectPurchaseId", params);

            let route = {
                name: "ManageProjectBookingRefunds",
                params: {
                    id: params.projectPurchaseId
                }
            };
            router.push(route);
        }
    },
    {
        label: LABELS.PROJECT_DEFECT_DETAIL,
        handle: function (params) {
            throwIfNotFound(this.label, "defectId", params);

            let route = {
                name: "ManageDefectsDetail",
                params: {
                    id: params.defectId
                }
            };
            router.push(route);
        }
    },

    // ================================ PROJECT ================================
    {
        label: LABELS.PROJECT_ADDED,
        handle: projectHandler.toProjectDetail
    },
    {
        label: LABELS.PROJECT_UPDATED,
        handle: projectHandler.toProjectDetail
    },
    {
        label: LABELS.PROJECT_BANKER_ALLOCATED,
        handle: projectHandler.toProjectBankerAllocation
    },
    {
        label: LABELS.PROJECT_BANKER_REMOVE,
        handle: projectHandler.toProjectBankerAllocation
    },
    {
        label: LABELS.PROJECT_LAWYER_ALLOCATED,
        handle: projectHandler.toProjectLawyerAllocation
    },
    {
        label: LABELS.PROJECT_LAWYER_REMOVED,
        handle: projectHandler.toProjectLawyerAllocation
    },
    {
        label: LABELS.PROJECT_PHASE_ADDED,
        handle: projectHandler.toProjectDetail
    },
    {
        label: LABELS.PROJECT_PHASE_UPDATED,
        handle: projectHandler.toProjectDetail
    },
    {
        label: LABELS.PROJECT_UNIT_TYPE_ADDED,
        handle: projectHandler.toProjectUnitTypeDetail
    },
    {
        label: LABELS.PROJECT_UNIT_TYPE_UPDATED,
        handle: projectHandler.toProjectUnitTypeDetail
    },
    {
        label: LABELS.PROJECT_FLOOR_PLAN_ADDED,
        handle: projectHandler.toProjectFloorPlanDetail
    },
    {
        label: LABELS.PROJECT_FLOOR_PLAN_UPDATED,
        handle: projectHandler.toProjectFloorPlanDetail
    },
    {
        label: LABELS.PROJECT_UNIT_ADDED,
        handle: projectHandler.toProjectFloorPlanDetail
    },
    {
        label: LABELS.PROJECT_UNIT_UPDATED,
        handle: projectHandler.toProjectFloorPlanDetail
    },
    {
        label: LABELS.PROJECT_UNIT_BANKER_ALLOCATED,
        handle: projectHandler.toProjectFloorPlanDetail
    },
    {
        label: LABELS.PROJECT_UNIT_BANKER_REMOVED,
        handle: projectHandler.toProjectFloorPlanDetail
    },
    {
        label: LABELS.PROJECT_UNIT_LAWYER_SPA_UPDATED,
        handle: projectHandler.toProjectFloorPlanDetail
    },
    {
        label: LABELS.PROJECT_UNIT_LAWYER_LA_UPDATED,
        handle: projectHandler.toProjectFloorPlanDetail
    }
];
